import React from 'react';
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import './assets/sitemenu.scss';
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function MenuProjects() {
    return (
        <Menu transition menuButton={<MenuButton>Projects <FontAwesomeIcon icon={faCaretDown}/></MenuButton>}>
            <MenuItem href="/projects">Projects</MenuItem>
            <MenuItem href="/alowcosteq6telescopepier">Low Cost Pier</MenuItem>
            <MenuItem href="/buildingafliptopobservatory">Flip Top Observatory</MenuItem>
            <MenuItem href="/eq6controlwitheqmodonwindows10">EQ6 Control from Windows</MenuItem>
            <MenuItem href="/tipsandtricks">Tips and Tricks</MenuItem>
        </Menu>
    )
}

