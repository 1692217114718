import React from 'react';
// See: https://www.davidhu.io/react-spinners/
// https://github.com/davidhu2000/react-spinners
import NiceLoader from "react-spinners/FadeLoader";
import "./assets/loadingSpinner.scss";

export default function LoadingSpinner({loading, height=10, width=10}: { loading?: any, height?: number, width?: number }) {
    const posW = (width / 2) - 30;
    const posH = (height / 2) - 30;

    return (
        <div className="spinnerWaitArea" style={{height: height, width: width}}>
            <div className="spinnerArea" style={{top: posH, left: posW}}>
                <NiceLoader loading={loading} color="#a83030"/>
            </div>
        </div>
    );
}
