import React from 'react';
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import './assets/sitemenu.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";

export default function MenuATM() {
    return (
        <Menu transition menuButton={<MenuButton>18-inch Dobsonian ATM <FontAwesomeIcon icon={faCaretDown}/></MenuButton>}>
            <MenuItem href="/atm18dobsonianterminuslux">Making a Large Dobsonian</MenuItem>
            <MenuItem href="/overview">Overview</MenuItem>
            <MenuItem href="/terminuslux">Naming</MenuItem>
            <MenuItem href="/scopeplans">Scope Plans</MenuItem>
            <MenuItem href="/secondarycage">Secondary Cage</MenuItem>
            <MenuItem href="/mirrorcell">Mirror Cell</MenuItem>
            <MenuItem href="/mirrorbox">Mirror Box</MenuItem>
            <MenuItem href="/rockerbox">Rocker Box</MenuItem>
            <MenuItem href="/poles">Poles</MenuItem>
            <MenuItem href="/bearings">Bearings</MenuItem>
            <MenuItem href="/control">Control</MenuItem>
            <MenuItem href="/completedscope">Completed Telescope</MenuItem>
        </Menu>
    )
}

