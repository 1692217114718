import React from 'react';
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import './assets/sitemenu.scss';
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function MenuCode() {
    return (
        <Menu transition menuButton={<MenuButton>Code <FontAwesomeIcon icon={faCaretDown}/></MenuButton>}>
            <MenuItem href="/code">Code</MenuItem>
            <MenuItem href="/androidopengltexturemappedspheres">Android Texture Mapped Sphere</MenuItem>
            <MenuItem href="/deepskylogger">Deep Sky Logger</MenuItem>
            <MenuItem href="/thiswebsite">This Web Site</MenuItem>
            <MenuItem href="/deepskystackerimagehelper">Deep Sky Stacker Helper</MenuItem>
        </Menu>
    )
}

