import React from 'react';
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import './assets/sitemenu.scss';
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function MenuMedia() {
    return (
        <Menu transition menuButton={<MenuButton>Media <FontAwesomeIcon icon={faCaretDown}/></MenuButton>}>
            <MenuItem href="/media">Media</MenuItem>
            <MenuItem href="/books">Books</MenuItem>
            <MenuItem href="/astronomysoftwarepackages">Software</MenuItem>
            <MenuItem href="/podcastsastronomicalandscientific">Podcasts</MenuItem>
            <MenuItem href="/kit">Kit</MenuItem>
        </Menu>
    )
}

