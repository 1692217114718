import React from 'react';
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import './assets/sitemenu.scss';
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function MenuHome() {
    return (
        <Menu transition menuButton={<MenuButton>Home <FontAwesomeIcon icon={faCaretDown}/></MenuButton>}>
            <MenuItem href="/">Home</MenuItem>
            <MenuItem href="/contactme">Contact Me</MenuItem>
            <MenuItem href="/donateorsaythanksforatalk">Say thanks and donate</MenuItem>
            <MenuItem href="/datacredits">Data Credits</MenuItem>
            <MenuItem href="/sitemap">Site Map</MenuItem>
            <MenuItem href="/about">About</MenuItem>
        </Menu>
    )
}

