import React from "react";
import "./assets/header.scss";
import jimsCosmosLogo from "../../assets/icons/jimsCosmosLogo.png";

const Header = () => (
    <div id="header" className="header">
        <div id="text-jims" className="text-jims">
            Jim&apos;s
        </div>
        <div id="logo" className="logo">
            <img id="logo-main" alt="Logo" src={jimsCosmosLogo} />
        </div>
        <div id="text-cosmos" className="text-cosmos">
            Cosmos
        </div>
    </div>
)

export default Header;
