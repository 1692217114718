import React from 'react';
import './assets/sitemenu.scss';
import MenuProjects from "./MenuProjects";
import MenuATM from "./MenuATM";
import MenuCode from "./MenuCode";
import MenuMedia from "./MenuMedia";
import MenuCharts from "./MenuCharts";
import MenuHome from "./MenuHome";
import MenuUser from "./MenuUser";
import Search from "../common/Search";

export default function HeaderFixed() {
    return (
        <div>
            <div className="header_fixed_left">
                <MenuHome/>
                <MenuCharts/>
                <MenuATM/>
                <MenuProjects/>
                <MenuCode/>
                <MenuMedia/>
            </div>
            <div className="header_fixed_right">
                <Search/>
                <MenuUser/>
            </div>
        </div>
    )
}

