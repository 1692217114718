import React from 'react';
import {Link} from "react-router-dom";
import './assets/footer.scss';
import toTopPng from "../../assets/gfx/goTop.png";

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export default function Footer() {

    return (
        <div id="footer" className="footer">
            <div id="text-copyright" className="text-copyright">
                &copy;2007 - {(new Date()).getFullYear()}
            </div>

            <div id="footer-links" className="footer-links">
                <Link to="/about">Website Design and coding Jim Cornmell</Link>
            </div>

            <div className="footer-links">
                <Link to="/sitemap">Site Map</Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to="/home">Home</Link>
            </div>

            <div className="toNavigators">
                {/*<div>*/}
                {/*    <Link to="/">*/}
                {/*        <img title="Home" src={toHomePng} alt="Home"/>*/}
                {/*    </Link>*/}
                {/*</div>*/}

                <div>
                    <img onClick={scrollToTop} title="Scroll to Top Of Page" alt="Top" src={toTopPng}/>
                </div>
            </div>
        </div>
    );
}

